<template>
  <div class="ticketchat-cnt">
    <header class="inner-header">
      <span class="icon-Btn goback" @click="$router.go(-1)">
        <back-icon />
      </span>
      <h1>Ticket ID : #458298</h1>

      <div class="right-colbtn">
        <span class="delete-ticket">
          <delete-icon /><strong>Delete</strong>
        </span>
      </div>
    </header>

    <div class="ticket-genrate">
      <p>15 days have passed since I haven't received my product yet</p>
      <div class="ticket-status">
        <div class="item-col">
          <span>20 Oct 2020</span>
        </div>

        <div class="item-col">
          <span>Status : </span>
          <span class="status-text">Open <arrow-bottom-icon /></span>
        </div>
      </div>
    </div>

    <section class="mid-cnt">
      <div class="ticket-logs">
        <h2>Conversation (02)</h2>
        <ul>
          <li>
            <div class="profile-head">
              <div class="profile-icon">
                <span>RJ</span>
              </div>
              <div class="profile-info">
                <h2>Ritu Raj</h2>
                <span class="text-date">21 Oct 20, 08:04 AM</span>
              </div>
            </div>
            <div class="chat-dtl">
              <p>Mujhe aapse payment related help chahiye</p>
            </div>
          </li>

          <li>
            <div class="profile-head">
              <div class="profile-icon">
                <span>ST</span>
              </div>
              <div class="profile-info">
                <h2>Support Team</h2>
                <span class="text-date">21 Oct 20, 08:54 AM</span>
              </div>
            </div>
            <div class="chat-dtl">
              <p>
                Hey Welcome to Mall91 Support<br />
                aap apna phone number and email confirm kar dijiyega
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div class="chat-bottom">
        <div class="note-msg">
          You will get response from support team with in 24 hours.
        </div>

        <div class="chat-inputbox">
          <input
            type="text"
            class="chat-input"
            placeholder="Type a message..."
          />

          <div class="option-btn">
            <span class="icon-Btn attached-btn">
              <attached-icon />
            </span>
            <span class="icon-Btn send-btn">
              <send-chat-icon />
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BackIcon from '@/components/SvgIcons/BackIcon';
import DeleteIcon from '@/components/SvgIcons/DeleteIcon';
import ArrowBottomIcon from '@/components/SvgIcons/ArrowBottomIcon';
import AttachedIcon from '@/components/SvgIcons/AttachedIcon';
import SendChatIcon from '@/components/SvgIcons/SendChatIcon';

export default {
  name: 'TicketChatLogList',
  components: {
    BackIcon,
    DeleteIcon,
    ArrowBottomIcon,
    AttachedIcon,
    SendChatIcon,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/ticket-chat-loglist.scss';
</style>
